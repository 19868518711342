<template>
  <div class="index">
    <keep-alive exclude="BudgetIndex,BudgetDetails">
      <router-view class="page"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style scoped lang="less">
.index {
  display: flex;
  flex-direction: column;
}
.page {
  flex: 1;
}
</style>